<template>
  <div class="individualAccount">
    <img
      src="https://cdn.bubbleplan.cn/static/marketplace/Frame9617.png"
      alt=""
      class="individualAccount-back"
      @click="back()"
    />
    <div class="individualAccount-left">
      <!-- <div
        class="individualAccount-left-back flex items-center flex-around"
        @click="back()"
      >
        <i class="el-icon-arrow-left"></i>
        <div class="individualAccount-left-back-border"></div>
        <div class="individualAccount-left-back-text">市场</div>
      </div> -->
      <div class="individualAccount-left-type">
        <div
          v-for="(item, index) in typeList"
          :key="index"
          :class="
            typeIndex == index
              ? 'individualAccount-left-type-li'
              : 'individualAccount-left-type-li1'
          "
          @click="typeButton(item, index)"
        >
          {{ item.name }}
        </div>
      </div>
    </div>
    <div class="individualAccount-right">
      <knapsack v-if="typeIndex == 0"></knapsack>
      <transactionHistory v-if="typeIndex == 1"></transactionHistory>
      <exchange v-if="typeIndex == 2"></exchange>
      <myorder v-if="typeIndex == 3"></myorder>
    </div>
  </div>
</template>


<script>
import knapsack from "./components/knapsack.vue";
import transactionHistory from "./components/transactionHistory.vue";
import exchange from "./components/exchange.vue";
import myorder from "./components/myorder.vue";
export default {
  components: { knapsack, transactionHistory, exchange, myorder },
  data() {
    return {
      typeIndex: 0,
      typeList: [
        {
          name: "背包",
        },
        {
          name: "交易历史",
        },
        {
          name: "芯片/芯片积分兑换",
        },
        {
          name: "我的订单",
        },
      ],

      classifyList: [
        {
          name: "芯片",
        },
        {
          name: "道具",
        },
        {
          name: "皮肤",
        },
        {
          name: "装备",
        },
        {
          name: "NFT",
        },
        {
          name: "NFT",
        },
        {
          name: "NFT",
        },
      ],
    };
  },
  mounted() {},
  methods: {
    typeButton(item, index) {
      this.typeIndex = index;
    },
    back() {
      history.back();
    },
  },
};
</script>




<style lang="scss" scoped>
.individualAccount::-webkit-scrollbar {
  width: 0px;
  height: 0px;
}
.individualAccount {
  width: 100%;
  height: calc(100vh - 94px);
  background: url("https://cdn.bubbleplan.cn/static/home/gather.png") no-repeat;
  background-size: 100% 100%;
  //   padding: 60px 0 0 330px;
  display: flex;
  justify-content: center;
  padding-top: 44.5px;
  overflow: auto;
  position: relative;
  &-back {
    position: absolute;
    top: 66px;
    left: 100px;
  }
  &-back:hover {
    cursor: pointer;
  }
  &-left {
    margin-right: -5px;

    &-back {
      width: 184px;
      height: 45px;
      border-radius: 276px;
      background: #22212c80;
      padding: 8px 0;
      .el-icon-arrow-left {
        color: #ffffff;
        font-size: 15px;
      }
      &-text {
        color: #ffffff99;
        font-size: 20px;
      }
      &-border {
        width: 2px;
        height: 100%;
        background: #1f1e25;
      }
    }

    &-type {
      display: flex;
      flex-direction: column;
      align-items: flex-end;
      margin-top: 127px;
      &-li:hover {
        cursor: pointer;
      }
      &-li1:hover {
        cursor: pointer;
      }
      &-li:nth-child(1) {
        width: 180px;
        height: 105px;
        background: url("https://cdn.bubbleplan.cn/static/marketplace/type_1.png")
          no-repeat;
        background-size: 100% 100%;
        color: #f1eae2;
        text-align: center;
        font-family: "TsangerYuMo";
        font-size: 24px;
        font-weight: bold;
        line-height: 105px;
        text-shadow: -1.5px -1.5px 0 #000000, 1.5px -1.5px 0 #000000,
          -1.5px 1.5px 0 #000000, 1.5px 1.5px 0 #000000;
        /* 模拟描边效果 */
      }
      &-li:nth-child(2) {
        width: 186px;
        height: 105px;
        background: url("https://cdn.bubbleplan.cn/static/marketplace/type_2.png")
          no-repeat;
        background-size: 100% 100%;
        color: #f1eae2;
        text-align: center;
        font-family: "TsangerYuMo";
        font-size: 24px;
        font-weight: bold;
        line-height: 105px;
        text-shadow: -1.5px -1.5px 0 #000000, 1.5px -1.5px 0 #000000,
          -1.5px 1.5px 0 #000000, 1.5px 1.5px 0 #000000;
        /* 模拟描边效果 */
        margin-top: -10px;
      }
      &-li:nth-child(3) {
        width: 186px;
        height: 105px;
        background: url("https://cdn.bubbleplan.cn/static/marketplace/type_3.png")
          no-repeat;
        background-size: 100% 100%;
        color: #f1eae2;
        text-align: center;
        font-family: "TsangerYuMo";
        font-size: 24px;
        font-weight: bold;

        text-shadow: -1.5px -1.5px 0 #000000, 1.5px -1.5px 0 #000000,
          -1.5px 1.5px 0 #000000, 1.5px 1.5px 0 #000000;
        /* 模拟描边效果 */
        margin-top: -10px;
        padding: 22px 12px;
      }
      &-li:nth-child(4) {
        width: 180px;
        height: 105px;
        background: url("https://cdn.bubbleplan.cn/static/marketplace/type_4.png")
          no-repeat;
        background-size: 100% 100%;
        color: #f1eae2;
        text-align: center;
        font-family: "TsangerYuMo";
        font-size: 24px;
        font-weight: bold;
        line-height: 105px;
        text-shadow: -1.5px -1.5px 0 #000000, 1.5px -1.5px 0 #000000,
          -1.5px 1.5px 0 #000000, 1.5px 1.5px 0 #000000;
        /* 模拟描边效果 */
        margin-top: -10px;
      }
      &-li1:nth-child(1) {
        width: 180px;
        height: 105px;
        background: url("https://cdn.bubbleplan.cn/static/marketplace/type_but1.png")
          no-repeat;
        background-size: 100% 100%;
        color: #f1eae2;
        text-align: center;
        font-family: "TsangerYuMo";
        font-size: 24px;
        font-weight: bold;
        line-height: 105px;
        text-shadow: -1.5px -1.5px 0 #000000, 1.5px -1.5px 0 #000000,
          -1.5px 1.5px 0 #000000, 1.5px 1.5px 0 #000000;
        /* 模拟描边效果 */
        opacity: 0.75;
      }
      &-li1:nth-child(2) {
        width: 186px;
        height: 106px;
        background: url("https://cdn.bubbleplan.cn/static/marketplace/type_but2.png")
          no-repeat;
        background-size: 100% 100%;
        color: #f1eae2;
        text-align: center;
        font-family: "TsangerYuMo";
        font-size: 24px;
        font-weight: bold;
        line-height: 106px;
        text-shadow: -1.5px -1.5px 0 #000000, 1.5px -1.5px 0 #000000,
          -1.5px 1.5px 0 #000000, 1.5px 1.5px 0 #000000;
        /* 模拟描边效果 */
        opacity: 0.75;
        margin-top: -10px;
      }
      &-li1:nth-child(3) {
        width: 180px;
        height: 106px;
        background: url("https://cdn.bubbleplan.cn/static/marketplace/type_but3.png")
          no-repeat;
        background-size: 100% 100%;
        color: #f1eae2;
        text-align: center;
        font-family: "TsangerYuMo";
        font-size: 24px;
        font-weight: bold;

        text-shadow: -1.5px -1.5px 0 #000000, 1.5px -1.5px 0 #000000,
          -1.5px 1.5px 0 #000000, 1.5px 1.5px 0 #000000;
        /* 模拟描边效果 */
        opacity: 0.75;
        margin-top: -10px;
        padding: 22px 12px;
      }
      &-li1:nth-child(4) {
        width: 180px;
        height: 106px;
        background: url("https://cdn.bubbleplan.cn/static/marketplace/type_but4.png")
          no-repeat;
        background-size: 100% 100%;
        color: #f1eae2;
        text-align: center;
        font-family: "TsangerYuMo";
        font-size: 24px;
        font-weight: bold;
        line-height: 106px;
        text-shadow: -1.5px -1.5px 0 #000000, 1.5px -1.5px 0 #000000,
          -1.5px 1.5px 0 #000000, 1.5px 1.5px 0 #000000;
        /* 模拟描边效果 */
        opacity: 0.75;
        margin-top: -10px;
      }
    }
  }
  &-right {
    width: 1068px;
    height: 760px;
    background: url("https://cdn.bubbleplan.cn/static/marketplace/A10_pop_img_004.png")
      no-repeat;
    background-size: 100% 100%;
    // margin-top: 45px;
    .individualAccount-right-type::-webkit-scrollbar {
      width: 0px;
      height: 0px;
    }
    &-type {
      :deep(.el-menu.el-menu--horizontal) {
        border-bottom: solid 0.005208rem #616161 !important;
      }
      :deep(.el-menu--horizontal) {
        background: transparent !important;
      }
      :deep(.el-menu--horizontal > .el-menu-item.is-active) {
        color: #fff !important;
        font-size: 20px;
        font-weight: 700;
        border-bottom: 0.010417rem solid #daff00;
      }
      :deep(.el-menu-item) {
        padding: 0 30px;
      }
      :deep(.el-menu-item):hover {
        background-color: transparent !important;
      }
    }
    &-commodity {
      margin-top: 30px;
      &-li {
        width: 221.86px;
        height: 254.23px;
        background: url("https://cdn.bubbleplan.cn/static/marketplace/commodity_bgc.png")
          no-repeat;
        background-size: 100% 100%;
        margin-bottom: 34.41px;
        &-imgbac {
          width: 100%;
          height: 185.45px;
          display: flex;
          align-items: center;
          justify-content: center;
        }
        &-img {
          width: 105.69px;
          height: 99.78px;
        }
        &-text {
          color: #ffffff;
          text-align: center;
          font-family: "qiantuhouheiti";
          font-size: 31.04px;
          line-height: 64.48px;
        }
      }
    }
  }
}
</style>