<template>
  <div class="transactionHistory">
    <div class="transactionHistory-type flex">
      <!-- <el-menu
        :default-active="typeIndex"
        class="el-menu-demo"
        mode="horizontal"
        @select="handleSelect"
        background-color="transparent"
        text-color="#fff"
      >
        <el-menu-item
          :index="index"
          v-for="(item, index) in typeList"
          :key="index"
          >{{ item.name }}</el-menu-item
        >
      </el-menu> -->
      <div
        v-for="(item, index) in typeList"
        :key="index"
        class=""
        :class="
          typeIndex == index
            ? 'transactionHistory-type-li1'
            : 'transactionHistory-type-li'
        "
        @click="handleSelect(index)"
      >
        {{ item.name }}
      </div>
    </div>
    <div class="transactionHistory-commodity flex" @scroll="handleScroll">
      <div
        v-for="(item, index) in orderList"
        :key="index"
        class="transactionHistory-commodity-li"
      >
        <div class="transactionHistory-commodity-li-box flex">
          <img
            :src="item.productImg"
            alt=""
            class="transactionHistory-commodity-li-box-img"
          />
          <div class="transactionHistory-commodity-li-box-right flex">
            <div class="transactionHistory-commodity-li-box-right-title">
              {{ item.productName }}
            </div>
            <div class="flex items-center flex-between">
              <div class="transactionHistory-commodity-li-box-right-title">
                x{{ item.count }}
              </div>
              <div class="transactionHistory-commodity-li-box-right-text">
                <span v-if="item.categoryId == 8 || item.categoryId == 29"
                  >￥</span
                >
                <span v-else>积分</span>
                {{ Math.floor(item.price * item.count * 100) / 100 }}
              </div>
            </div>
          </div>
        </div>
        <div class="transactionHistory-commodity-li-border"></div>
        <div
          class="flex items-center flex-between transactionHistory-commodity-li-bottom"
        >
          <div>交易时间</div>
          <div>{{ item.createTime }}</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      typeIndex: 0,
      typeList: [
        {
          name: "买入",
        },
        {
          name: "售出",
        },
        {
          name: "转入",
        },
        {
          name: "转出",
        },
      ],
      page: {
        pageNum: 1,
        pageSize: 10,
      },
      hasNextPage: false,
      type: 1,
      orderList: [],
    };
  },
  mounted() {
    this.buyOrSell();
  },
  methods: {
    //列表
    async buyOrSell(type) {
      const params = {
        ...this.page,
        type: this.type,
      };
      const { data, code } = await this.$ajax.buyOrSell(params);
      if (type == "bottom") {
        this.orderList.push(...data.list);
      } else {
        this.orderList = data.list;
      }
      this.orderList = [];
      this.hasNextPage = data.hasNextPage;
    },
    handleScroll(event) {
      const { scrollTop, clientHeight, scrollHeight } = event.target;
      // 检查是否滚动到底部
      if (scrollTop + clientHeight >= scrollHeight) {
        if (this.hasNextPage) {
          this.page.pageNum++;
          this.myOrder("bottom");
        }
      }
    },
    handleSelect(key, keyPath) {
      console.log(key, keyPath);
      this.typeIndex = key;
      this.page.pageNum = 1;
      if (this.typeIndex == 0) {
        this.type = 1;
        this.buyOrSell();
      } else if (this.typeIndex == 1) {
        this.type = 0;
        this.buyOrSell();
      } else {
        this.orderList = [];
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.transactionHistory {
  padding: 33px 28px;
  &-type {
    margin: auto;
    width: 638px;
    height: 83.45px;
    border-radius: 9.7px;
    background: #685c84;
    box-shadow: 0 3.88px 3.88px 0 #00000040 inset;
    overflow: auto;
    padding: 15px 18px;
    padding-left: 0;
    &-li:hover {
      cursor: pointer;
    }
    &-li1:hover {
      cursor: pointer;
    }
    &-li1 {
      width: 140px;
      height: 52px;
      line-height: 52px;
      background: url("https://cdn.bubbleplan.cn/static/marketplace/Group10493.png")
        no-repeat;
      background-size: 100% 100%;
      color: #2f2f2f;
      text-align: center;
      font-family: "TsangerYuMo";
      font-size: 22px;
      font-weight: bold;
      margin-left: 16px;
    }
    &-li {
      width: 140px;
      height: 52px;
      line-height: 52px;
      background: url("https://cdn.bubbleplan.cn/static/marketplace/Group10494.png")
        no-repeat;
      background-size: 100% 100%;
      color: #2f2f2f;
      text-align: center;
      font-family: "TsangerYuMo";
      font-size: 22px;
      font-weight: bold;
      margin-left: 16px;
    }
    :deep(.el-menu.el-menu--horizontal) {
      border-bottom: solid 0.005208rem #616161 !important;
    }
    :deep(.el-menu--horizontal) {
      background: transparent !important;
    }
    :deep(.el-menu--horizontal > .el-menu-item.is-active) {
      color: #fff !important;
      font-size: 20px;
      font-weight: 700;
      border-bottom: 0.010417rem solid #daff00;
    }
    :deep(.el-menu-item) {
      padding: 0 30px;
    }
    :deep(.el-menu-item):hover {
      background-color: transparent !important;
    }
  }
  &-commodity::-webkit-scrollbar {
    width: 0px;
    height: 0px;
  }
  &-commodity {
    height: 621px;
    overflow: auto;
    margin-top: 17px;
    padding: 40px 46px;
    flex-wrap: wrap;
    &-li:nth-child(2n) {
      margin-right: 0px;
    }
    &-li {
      width: 420px;
      height: 195px;
      background: #f3ece4;
      border-radius: 5px;
      margin-right: 18px;
      margin-bottom: 20px;
      padding: 16px 15px;
      &-box {
        &-img {
          width: 91px;
          height: 91px;
          margin-right: 18.13px;
        }
        &-right {
          flex: 1;
          flex-direction: column;
          justify-content: space-between;
          &-title {
            color: #4d3283;
            font-size: 18px;
          }
          &-text {
            color: #4d3283;
            font-size: 20px;
            font-weight: Black;
          }
        }
      }
      &-border {
        margin: 18px 0;
        width: 100%;
        height: 2px;
        background: rgba(0, 0, 0, 0.2);
      }
      &-bottom {
        color: rgba(127, 113, 153, 1);
        font-size: 15px;
      }
    }
  }
}
</style>