<template>
  <div class="myorder">
    <div class="myorder-type flex">
      <div
        v-for="(item, index) in typeList"
        :key="index"
        class=""
        :class="typeIndex == index ? 'myorder-type-li1' : 'myorder-type-li'"
        @click="typeButtonm(index)"
      >
        <!-- <img
          src="https://cdn.bubbleplan.cn/static/marketplace/Maskgroup2x.png"
          alt=""
          class="myorder-type-li-img"
          v-if="typeIndex == index"
        /> -->
        {{ item.name }}
      </div>
    </div>
    <div class="myorder-classify">
      <el-menu
        :default-active="classifyIndex.toString()"
        class="el-menu-demo"
        mode="horizontal"
        @select="handleSelect"
        background-color="transparent"
        text-color="#fff"
      >
        <el-menu-item
          :index="index.toString()"
          v-for="(item, index) in classifyList"
          :key="index"
          >{{ item.name }}</el-menu-item
        >
      </el-menu>
    </div>
    <div class="myorder-list flex" @scroll="handleScroll">
      <div
        v-for="(item, index) in orderList"
        :key="index"
        class="myorder-list-li"
      >
        <div class="myorder-list-li-top flex">
          <img :src="item.img" alt="" class="myorder-list-li-top-img" />
          <div class="flex flex-direction flex-between" style="flex: 1">
            <div class="myorder-list-li-top-title">
              {{ item.productName }}
            </div>
            <div class="myorder-list-li-top-num">x{{ item.totalCount }}</div>
            <div class="flex items-center flex-between">
              <div class="myorder-list-li-top-pri">
                <span v-if="item.categoryId == 8 || item.categoryId == 29"
                  >￥</span
                >
                <span v-else>积分</span>{{ item.price }}
              </div>
              <div style="color: rgb(127, 113, 153)">
                当前还有<span style="color: rgb(255, 0, 231)">{{
                  (item.totalCount - item.purchasedCount).toFixed(0)
                }}</span
                >个
              </div>
            </div>
          </div>
        </div>
        <div class="myorder-list-li-border"></div>
        <div class="flex items-center flex-between myorder-list-li-button">
          <!-- <div class="myorder-list-li-button-text">
            当前还有<span style="color: #daff00">{{
              (item.totalCount - item.purchasedCount).toFixed(0)
            }}</span
            >个
          </div> -->
          <div style="color: rgb(127, 113, 153)">
            {{ item.createTime }}
          </div>
          <div>
            <div
              class="flex"
              v-if="item.status == 0"
              style="align-items: center"
            >
              <div>进行中</div>
              <div class="myorder-list-li-button-but" @click="cancel(item)">
                {{ typeIndex == 0 ? "取消寄售" : "取消求购" }}
              </div>
            </div>
            <div class="myorder-list-li-button-but" v-else>
              {{
                item.status == 1 ? "已完成" : item.status == 2 ? "已取消" : ""
              }}
            </div>
          </div>
        </div>
      </div>
    </div>

    <el-dialog title="" :visible.sync="show" width="30%" :show-close="false">
      <div class="passwordw">
        <img
          src="https://cdn.bubbleplan.cn/static/marketplace/X.png"
          alt=""
          class="passwordw-img"
          @click="show = !show"
        />
        <div class="passwordw-title">
          取消{{ typeIndex == 0 ? "寄售" : "求购" }}
        </div>
        <div class="passwordw-num">
          确定取消{{ typeIndex == 0 ? "寄售" : "求购" }}该商品？
        </div>
        <div class="passwordw-button flex flex-between">
          <div class="passwordw-button-right" @click="cancelOrder()"></div>
          <div class="passwordw-button-left" @click="show = false"></div>
        </div>
      </div>
    </el-dialog>
  </div>
</template>
<script>
export default {
  data() {
    return {
      show: false,
      classifyIndex: 0,
      typeIndex: 0,
      typeList: [
        {
          name: "寄售订单",
        },
        {
          name: "求购订单",
        },
      ],
      classifyList: [],
      orderList: [],
      hasNextPage: false,
      page: {
        pageNum: 1,
        pageSize: 10,
      },
      categoryId: null,
      singleData: {},
    };
  },
  mounted() {
    // this.getLevelTwoTypeList();
    this.getTypeList();
  },

  methods: {
    //获取分类
    async getTypeList() {
      const { data, code } = await this.$ajax.getTypeList();
      this.classifyList = data;
      this.categoryId = this.classifyList[0].categoryId;
      this.myOrder();
    },
    //取消订单
    async cancelOrder() {
      const params = {
        id: this.singleData.id,
        systemType: "PC",
      };
      const { data, code } = await this.$ajax.cancelOrder(params);
      if (code == 200) {
        this.page.pageNum = 1;
        this.scrollTop = this.scrollTop == 0 ? 0.1 : 0;
        this.myOrder();
      }
      this.show = false;
    },
    cancel(item) {
      this.singleData = item;
      this.show = true;
    },

    handleScroll(event) {
      const { scrollTop, clientHeight, scrollHeight } = event.target;
      // 检查是否滚动到底部
      if (scrollTop + clientHeight >= scrollHeight) {
        if (this.hasNextPage) {
          this.page.pageNum++;
          this.myOrder("bottom");
        }
      }
    },
    //获取订单
    async myOrder(type) {
      const params = {
        ...this.page,
        type: this.typeIndex == 0 ? 1 : 0,
        categoryId: this.categoryId,
      };
      const { data, code } = await this.$ajax.myOrder(params);
      if (type == "bottom") {
        this.orderList.push(...data.list);
      } else {
        this.orderList = data.list;
      }
      this.hasNextPage = data.hasNextPage;
    },
    //获取二级分类
    async getLevelTwoTypeList() {
      const { data, code } = await this.$ajax.getLevelTwoTypeList();
      this.classifyList = data;
      this.categoryId = this.classifyList[0].categoryId;
      this.myOrder();
    },
    handleSelect(key, keyPath) {
      //   console.log(key, keyPath);
      this.categoryId = this.classifyList[key].categoryId;
      this.page.pageNum = 1;
      this.myOrder();
    },
    typeButtonm(index) {
      this.typeIndex = index;
      this.page.pageNum = 1;
      this.myOrder();
    },
  },
};
</script>

<style lang="scss" scoped>
.myorder {
  padding: 34px 73px;
  padding-right: 135px;
  :deep(.el-dialog__header) {
    padding: 0;
  }
  :deep(.el-dialog__body) {
    padding: 0;
  }
  :deep(.el-dialog) {
    background: transparent !important;
  }
  &-type {
    margin: auto;
    width: 380px;
    height: 83.45px;
    border-radius: 9.7px;
    background: #685c84;
    box-shadow: 0 3.88px 3.88px 0 #00000040 inset;
    overflow: auto;
    padding: 15px 18px;
    padding-left: 0;
    &-li:hover {
      cursor: pointer;
    }
    &-li1:hover {
      cursor: pointer;
    }
    &-li1 {
      width: 169px;
      height: 52px;
      line-height: 52px;
      background: url("https://cdn.bubbleplan.cn/static/marketplace/Group10493.png")
        no-repeat;
      background-size: 100% 100%;
      color: #2f2f2f;
      text-align: center;
      font-family: "TsangerYuMo";
      font-size: 22px;
      font-weight: bold;
      margin-left: 16px;
    }
    &-li {
      width: 169px;
      height: 52px;
      line-height: 52px;
      background: url("https://cdn.bubbleplan.cn/static/marketplace/Group10494.png")
        no-repeat;
      background-size: 100% 100%;
      color: #2f2f2f;
      text-align: center;
      font-family: "TsangerYuMo";
      font-size: 22px;
      font-weight: bold;
      margin-left: 16px;
    }
  }
  //   ul::-webkit-scrollbar {
  //     width: 10px;
  //     height: 10px;
  //   }
  &-classify {
    width: 100%;
    margin-top: 30px;
    padding-left: 10px;
    ul::-webkit-scrollbar {
      width: 8px;
      height: 8px;
    }
    ul::-webkit-scrollbar-track {
      background: #f1f1f1; /* 设置轨道的背景颜色 */
    }
    ul::-webkit-scrollbar-thumb {
      background: #888; /* 设置滑块的背景颜色 */
    }
    ul::-webkit-scrollbar-thumb:hover {
      background: #555; /* 设置滑块在悬停状态下的背景颜色 */
    }

    :deep(.el-menu.el-menu--horizontal) {
      display: flex;
      overflow: auto;
      border-bottom: solid 0.005208rem #616161 !important;
    }
    :deep(.el-menu--horizontal) {
      background: transparent !important;
    }
    :deep(.el-menu--horizontal > .el-menu-item.is-active) {
      color: rgba(107, 81, 195, 1) !important;
      font-size: 18px !important;
      font-weight: 700;
      border-bottom: 0.010417rem solid rgba(107, 81, 195, 1);
    }
    :deep(.el-menu-item) {
      padding: 0 30px;
      color: rgb(104, 92, 132) !important;
      font-weight: Bold !important;
      font-size: 18px !important;
    }
    :deep(.el-menu-item):hover {
      background-color: transparent !important;
    }
  }
  &-list::-webkit-scrollbar {
    width: 0px;
    height: 0px;
  }
  &-list {
    height: 470px;
    overflow: auto;
    margin-top: 22px;
    flex-wrap: wrap;
    &-li:nth-child(2n) {
      margin-right: 0;
    }
    &-li {
      width: 420px;
      height: 201px;
      background: rgba(243, 236, 228, 1);
      background-size: 100% 100%;
      padding: 21px 18px;
      margin-bottom: 20px;
      margin-right: 19px;
      border-radius: 8px;
      &-top {
        &-img {
          width: 91px;
          height: 91px;
          margin-right: 12.91px;
        }
        &-title {
          color: rgb(77, 50, 131);
          font-size: 20px;
        }
        &-num {
          color: rgb(77, 50, 131);
          font-weight: bold;
          font-size: 20px;
        }
        &-pri {
          color: rgb(77, 50, 131);
          font-size: 20px;
          font-weight: bold;
        }
      }
      &-border {
        margin: 17px 0;
        width: 100%;
        height: 1px;
        background: rgba(0, 0, 0, 0.2);
      }
      &-button {
        &-text {
          color: #a09db5;
          font-size: 15px;
        }
        &-but:hover {
          cursor: pointer;
        }
        &-but {
          width: 96px;
          height: 42px;
          background: url("https://cdn.bubbleplan.cn/static/marketplace/Vector606.png")
            no-repeat;
          background-size: 100% 100%;
          color: rgb(243, 233, 208);
          text-shadow: 0 1.73px 0 rgba(0, 0, 0, 0.25);
          font-size: 18px;
          font-weight: bold;
          text-align: center;
          font-size: 16px;
          line-height: 42px;
        }
      }
    }
  }
  :deep(.el-dialog) {
    box-shadow: none !important;
  }
  .passwordw {
    width: 488px;
    height: 217px;
    background: url("https://cdn.bubbleplan.cn/static/marketplace/488-271.png")
      no-repeat;
    background-size: 100% 100%;
    position: relative;
    padding-top: 10px;
    &-img {
      position: absolute;
      top: 0px;
      right: -12px;
      width: 48px;
      height: 48px;
    }
    &-title {
      text-align: center;
      color: rgb(87, 67, 82);
      font-size: 24px;
      font-family: "TsangerYuMo";
      font-weight: bold;
    }
    &-num {
      color: rgb(87, 67, 82);
      font-size: 22px;
      text-align: center;
      margin-top: 60px;
      font-weight: bold;
    }
    &-button {
      padding: 0 82px;
      margin-top: 52px;
      &-left:hover {
        cursor: pointer;
      }
      &-left {
        width: 141px;
        height: 56px;
        text-align: center;
        background: url("https://cdn.bubbleplan.cn/static/marketplace/Group10206.png")
          no-repeat;
        background-size: 100% 100%;
        line-height: 67px;
        color: #2d2d2d;
        font-size: 24px;
        font-weight: 700;
      }
      &-right:hover {
        cursor: pointer;
      }
      &-right {
        width: 141px;
        height: 56px;
        text-align: center;
        background: url("https://cdn.bubbleplan.cn/static/marketplace/Group10207.png")
          no-repeat;
        background-size: 100% 100%;
        line-height: 67px;
        color: #2d2d2d;
        font-size: 24px;
        font-weight: 700;
      }
    }
  }
}
</style>