import { render, staticRenderFns } from "./knapsack.vue?vue&type=template&id=356d7297&scoped=true"
import script from "./knapsack.vue?vue&type=script&lang=js"
export * from "./knapsack.vue?vue&type=script&lang=js"
import style0 from "./knapsack.vue?vue&type=style&index=0&id=356d7297&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../../node_modules/.store/vue-loader@15.11.1/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "356d7297",
  null
  
)

export default component.exports